import { lazy } from "react";
import { Navigate } from "react-router-dom";
import HomeLayout from "../layouts/HomeLayout";
import DashboardLayout from "../layouts/DashboardLayout";
import AuthLayout from "../layouts/AuthLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/newHome")),
  },
  {
    exact: true,
    path: "/view-subscribe",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/newHome/loansubscriber/ViewSubscribe.jsx")
    ),
  },
  {
    exact: true,
    path: "/borrow",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/newHome/AseetBorrowCard.jsx")),
  },
  {
    exact: true,
    path: "/oldHome",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/home")),
  },

  //--Rewards --//

  {
    exact: true,
    guard: true,
    path: "/rewards",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/rewards")),
  },

  //---Announcements---//
  {
    exact: true,
    path: "/resources/:id",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/announcement/PreviewPage.jsx")
    ),
  },

  //user-profile
  {
    exact: true,
    guard: true,
    path: "/user-profile",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/profile")),
  },
  {
    exact: true,
    guard: true,
    path: "/referrals",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/referrals")),
  },

  //user-kyc-steps
  {
    exact: true,
    guard: true,
    // path: "/user-kyc-info",
    path: "/user-account-settings",
    layout: DashboardLayout,
    component: lazy(() => import("../views/pages/kyc")),
  },

  // {
  //   exact: true,
  //   guard: true,
  //   path: "/user-account-settings",
  //   layout: DashboardLayout,
  //   component: lazy(() => import("../views/pages/settings")),
  // },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/about")),
  },
  {
    exact: true,
    path: "/terms-conditions",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/terms")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/policy")),
  },
  {
    exact: true,
    path: "/privacy-policy-account-deletion",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/policy/AccountDeletionPolicy.jsx")
    ),
  },
  {
    exact: true,
    path: "/kyc-policy",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/kycpolicy")),
  },
  {
    exact: true,
    path: "/borrow-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/borrowpolicy/BorrowPolicy.jsx")
    ),
  },
  {
    exact: true,
    path: "/bnpl-policy",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/bnplpolicy/BNPLPolicy.jsx")),
  },
  {
    exact: true,
    path: "/account-deletion-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/accountdeletionpolicy/AccountDeletionPolicy.jsx")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/business-contact",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/contact")),
  },

  //user-authentication------------------------------------------------------
  {
    loginguard: true,
    exact: true,
    path: "/login",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/Login")),
  },
  {
    loginguard: true,
    exact: true,
    path: "/signup",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/SingUp")),
  },
  {
    loginguard: true,
    exact: true,
    path: "/forget-password",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/ForgetPassword.jsx")),
  },
  {
    loginguard: true,
    exact: true,
    path: "/reset-password",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/ResetPassword.jsx")),
  },

  {
    exact: true,
    path: "/verify-otp",
    layout: AuthLayout,
    component: lazy(() => import("../views/pages/auth/Verify")),
  },
  //user-authentication------------------------------------------------------

  {
    exact: true,
    guard: true,
    path: "/wallet",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/overview")),
  },
  {
    exact: true,
    guard: true,
    path: "/leverages",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/wallet/overview/Leverages.jsx")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-history",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/wallet/overview/WalletHistory.jsx")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/taral-history",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/wallet/overview/TaralHistory.jsx")
    ),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-crypto-deposit",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/cryptoDeposit")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-crypto-withdraw",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/cryptoWithdraw")),
  },
  {
    exact: true,
    guard: true,
    path: "/wallet-crypto-transfer",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/wallet/transfer")),
  },

  //taral-product------------------------------------------------------

  {
    exact: true,
    guard: true,
    path: "/product-taral-banking",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/poducts/taralBanking")),
  },

  // {
  //   exact: true,
  //   guard: true,
  //   path: "/product-taral-banking-transfer",
  //   layout: HomeLayout,
  //   component: lazy(() => import("../views/pages/wallet/transfer")),
  // },

  {
    exact: true,
    guard: true,
    path: "/product-bnpl",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/poducts/bnpl")),
  },
  {
    exact: true,
    guard: true,
    path: "/product-bnpl-invoice",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/poducts/bnpl/Invoice")),
  },
  {
    exact: true,
    guard: true,
    path: "/product-bnpl-invoice-new",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/poducts/bnpl/InvoiceNew")),
  },
  {
    exact: true,
    path: "/fixed-deposit",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/poducts/FixedDeposit/index")),
  },
  {
    exact: true,
    guard: true,
    path: "/product-fixed-deposit-invoice",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/poducts/FixedDeposit/InvoiceFD")
    ),
  },

  //support section
  {
    exact: true,
    guard: true,
    path: "/support/raise-tickets",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/support")),
  },
  {
    exact: true,
    guard: true,
    path: "/buy-crypto-fast-trading",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/trade/fastTrade")),
  },
  {
    exact: true,
    guard: true,
    path: "/product-taral-earn",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/poducts/taralEarn")),
  },

  {
    exact: true,
    guard: true,
    path: "/product-taral-drop",
    layout: HomeLayout,
    component: lazy(() => import("../views/pages/poducts/taralDrop")),
  },
  {
    exact: true,
    guard: true,
    path: "/product-taral-earn-info",
    layout: HomeLayout,
    component: lazy(() =>
      import("../views/pages/poducts/taralEarn/TaralearnInfo")
    ),
  },

  {
    exact: true,
    path: "*",
    component: lazy(() => import("../views/errors/NotFound")),
  },

  {
    component: () => <Navigate to="/404" />,
  },
];

import { ThemeProvider } from "@mui/material";
import { useContext } from "react";
import { createAppTheme } from "./theme";
import ThemeContext from "./context/themeContext";
import { routes } from "./routes";
import { RenderRoutes } from "./routes/renderRoutes";
import "react-phone-input-2/lib/style.css";
import { Toaster } from "react-hot-toast";
import "animate.css/animate.min.css";
import CookieConsent from "react-cookie-consent";
import AuthContext from "./context/auth";
import FlexLayerContext from "./context/flexLayer";

window.Buffer = window.Buffer || require("buffer").Buffer;
function App() {
  const themeSettings = useContext(ThemeContext);
  const theme = createAppTheme({
    theme: themeSettings.settings.theme,
  });
  return (
    <div className="App">
      <AuthContext>
        <FlexLayerContext>
          <ThemeProvider theme={theme}>
            <RenderRoutes data={routes} />
            <Toaster
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              // theme="light"
              theme={theme.palette.type}
            />
            <CookieConsent>
              This website uses cookies to enhance the user experience.
            </CookieConsent>
          </ThemeProvider>
        </FlexLayerContext>
      </AuthContext>
    </div>
  );
}

export default App;

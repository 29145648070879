import React, { useContext, useState } from "react";
// import { Link as RouterLink } from 'next/link'
import clsx from "clsx";
import PropTypes from "prop-types";
import { Button, Collapse, IconButton, ListItem } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { styled } from "@mui/system"; // Import styled from @mui/system

import { useLocation, useNavigate } from "react-router";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { AuthContext } from "../../../context/auth";
import toast from "react-hot-toast";
import { colors } from "../../../assets/colors";

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "block",
  paddingTop: 0,
  paddingBottom: 0,
  [theme.breakpoints.up("md")]: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0,
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  // padding: "13px 20px",
  justifyContent: "flex-start",
  textTransform: "none",
  marginBottom: "8px",
  letterSpacing: 0,
  width: "100%",
  fontWeight: 400,
  "&:hover": {
    color: "#ffff",
    background: theme.palette.background.active,
    borderRadius: "0px",
    // "& $icon": {
    //   color: "#ffff",
    // },
  },
}));

const StyledButtonLeaf = styled(Button)(({ theme }) => ({
  color: "#0D0D0D",
  // padding: "13px 20px",
  justifyContent: "flex-start",
  textTransform: "none",
  letterSpacing: 0,
  width: "100%",
  marginBottom: "5px",
  borderRadius: 0,
  opacity: 1,
  fontWeight: 400,
  fontSize: "13px",
  // "& $icon": {
  //   color: "#0D0D0D",
  // },
  "& li": {
    "& $title": {
      marginLeft: "30px",
    },
  },
  "&:hover, &.active": {
    color: theme.palette.secondary.main,
    background: theme.palette.primary.main,
    borderRadius: "0px",
    // "& $icon": {
    //   color: theme.palette.secondary.main,
    // },
  },
  "&.depth-0": {
    "& $title": {
      fontWeight: 400,
      fontSize: "12px",
      whiteSpace: "pre",
    },
  },
}));

const StyledIcon = styled("span")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginRight: theme.spacing(2),
  marginLeft: theme.spacing(1),
  color: theme.palette.primary.main,
}));

const StyledTitle = styled("span")(({ theme }) => ({
  marginRight: "auto",

  // color: theme.palette.secondary.main,
  color: colors.darkGrey,
}));

const StyledCollapse = styled(Collapse)({
  // marginLeft: "45px",
});

const NavItem = ({
  children,
  className,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp,
  title,
  tabview,
  setSelectedTab,
  onMobileClose,
  type,
  ...rest
}) => {
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const router = useLocation();
  const [open, setOpen] = useState(openProp);
  const [openLogout, setOpenLogout] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // let paddingLeft = 8;

  // if (depth > 0) {
  //   paddingLeft = 32 + 8 * depth;
  // }

  const isActive = router.pathname === href;

  if (children) {
    return (
      <React.Fragment>
        <StyledListItem
          className={clsx(className)}
          onClick={onMobileClose}
          disableGutters
          key={title}
          {...rest}
        >
          <StyledButton
            style={{
              padding: type && type === "mobile" ? "0px 7px" : "13px 20px",
            }}
            onClick={handleToggle}
            endIcon={
              <IconButton>
                {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            }
          >
            {Icon && <StyledIcon>{<Icon size="20" />}</StyledIcon>}
            <StyledTitle>{title}</StyledTitle>
            {/* <IconButton>
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton> */}
          </StyledButton>
        </StyledListItem>
        <StyledCollapse in={open}>{children}</StyledCollapse>
      </React.Fragment>
    );
  }
  const logoutHandler = () => {
    auth.userLogIn(false, null);
    toast.success("You have successfully logged out!");
    navigate("/login");
  };
  return (
    <StyledListItem
      className={clsx(className)}
      disableGutters
      key={title}
      {...rest}
    >
      <StyledButtonLeaf
        style={{ padding: type && type === "mobile" ? "7px 7px" : "13px 20px" }}
        className={clsx(`depth-${depth}`, isActive && "active")}
        onClick={() => {
          if (href === "/logout") {
            setOpenLogout(true);
          } else {
            navigate(href);
          }
        }}
      >
        {Icon && <StyledIcon>{Icon}</StyledIcon>}
        <StyledTitle>{title}</StyledTitle>
        {Info && <Info />}
      </StyledButtonLeaf>
      {openLogout && (
        <ConfirmationModal
          open={openLogout}
          title="Logout!"
          description="Are you sure want to logout?"
          onClick={logoutHandler}
          close={() => setOpenLogout(false)}
        />
      )}
    </StyledListItem>
  );
};

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  info: PropTypes.elementType,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onMobileClose: PropTypes.func,
};

NavItem.defaultProps = {
  open: false,
};

export default NavItem;

import React, { createContext, useEffect, useState } from "react";
import io from "socket.io-client";
import { notification_socket_url, socket_url } from "../api";
import { socket_access_token } from "../contants";

export const FlexLayerContext = createContext();

export default function FlexLayerProvider(props) {
  const [coinListData, setCoinListData] = useState([]);
  const [tradeHistory, setTradeHistory] = useState([]);
  // console.log(">>>>>>>coinListData", coinListData);
  const [notificationData, setNotificationData] = useState({});
  const [isConnected, setIsConnected] = useState(false);
  /*
  function to create a socket connection once 
  the user lands to the application 
  and also diconnect the same connection once the user leaves
  */

  useEffect(() => {
    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/signup" &&
      window.location.pathname !== "/forget-password"
    ) {
      // const socket = io(notification_socket_url);
      const socket = io(notification_socket_url, {
        transports: ["websocket", "polling", "flashsocket"],
        secure: true,
        reconnect: true,
        rejectUnauthorized: false,
      });
      socket.on("connect", () => {
        setIsConnected(true);
      });

      if (window.sessionStorage.getItem("user_id")) {
        // Emitter to get the cup with the user ID
        socket.emit("userConnected", {
          userId: window.sessionStorage.getItem("user_id"),
        });
        // Emitter to get the cup with the user ID
        socket.emit("notification", {
          userId: window.sessionStorage.getItem("user_id"),
        });
        //emitter to get the cup
        socket.on("notification", (data) => {
          // console.log("notification--", data);
          setNotificationData(data);
        });
      }

      // Clean up socket connection when the component unmounts
      return () => {
        socket.disconnect();
      };
    }
  }, [window.sessionStorage.getItem("user_id")]);

  useEffect(() => {
    if (
      window.location.pathname !== "/login" &&
      window.location.pathname !== "/signup" &&
      window.location.pathname !== "/forget-password"
    ) {
      const socket = io(socket_url, {
        // transports: ["websocket", "polling", "flashsocket"],
        // secure: true,
        // reconnect: true,
        // rejectUnauthorized: false,
        auth: {
          token: socket_access_token,
        },
      });

      socket.on("connect", () => {
        console.log("Connected to the server!");
      });

      //emitter to get the cup
      socket.on("cmc_updated", (data) => {
        setCoinListData(data);
      });

      socket.on("order_history_updated", (data) => {
        setTradeHistory(data);
      });

      // Clean up socket connection when the component unmounts
      return () => {
        socket.disconnect();
      };
    }
  }, [window.location.pathname]);

  let data = {
    coinListData,
    tradeHistory,
    notificationData,
  };

  return (
    <FlexLayerContext.Provider value={data}>
      {props.children}
    </FlexLayerContext.Provider>
  );
}

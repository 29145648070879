import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
export default function SuccessModal({ open, close, title, description }) {
  return (
    <Dialog open={open} onClose={close} fullWidth maxWidth="xs">
      <Box className="gradientBox">
        <DialogTitle position="relative">
          <Typography variant="h4" textAlign="center">
            {title}
          </Typography>
          <Box
            sx={{ position: "absolute", top: "8px", right: "8px", zIndex: 9 }}
          >
            <IconButton onClick={close}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box align="center" style={{ padding: "25px 0px 0px" }}>
            <Box>
              {/* <img src="/assets/images/status.gif" alt="" /> */}
              <img src="/assets/logo/done.gif" alt="" />
            </Box>
            <Box mb={3} mt={2}>
              <Typography style={{ fontWeight: "600" }} variant="h5">
                {description}
              </Typography>
            </Box>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}

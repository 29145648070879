import React, { createContext, useState, useEffect } from "react";
import { apiRouterCall } from "../api/services";
import axios from "axios";
import { calculateTimeLeft } from "../utils";

import Cookies from "js-cookie";
export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("access_token", accessToken);
    axios.defaults.headers.common["Authorization"] = `${accessToken}`;
  } else {
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("user_id");
    delete axios.defaults.headers.common["Authorization"];
  }
};

function checkLogin() {
  const accessToken = window.sessionStorage.getItem("access_token");
  return accessToken ? true : false;
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [userData, setUserData] = useState({
    profile: {},
    wallet: [],
    kycDetails: {},
  });
  const [endTime, setEndtime] = useState();
  const [timeLeft, setTimeLeft] = useState();
  const [kycStatus, setKycStatus] = useState(0);
  const [viewBank, setViewBank] = useState({});
  const [viewNominee, setViewNominee] = useState({});
  const [staticContent, setStaticContent] = useState([]);
  const [bannerList, setBannerList] = useState([]);
  const [isMaintenanceActive, setIsMaintenanceActive] = useState(false);
  const [dataLoading, setDataLoading] = useState(true);
  const [bannerLoading, setBannerLoading] = useState(true);

  const dataHandler = async () => {
    try {
      setBannerLoading(true);
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "bannerList",
      });
      if (response.data.responseCode === 200) {
        setBannerList(response?.data?.result);
      }
      setBannerLoading(false);
    } catch (error) {
      console.log(error);
      setBannerLoading(false);
    }
  };

  useEffect(() => {
    if (
      window.location.pathname === "/wallet" ||
      window.location.pathname === "/fixed-deposit" ||
      window.location.pathname === "/borrow" ||
      window.location.pathname === "/buy-crypto-fast-trading" ||
      window.location.pathname === "/product-bnpl"
    ) {
      dataHandler();
    }
  }, []);

  //function to get user profile and walet
  const getUserProfileHandler = async (user_id) => {
    if (window.location.pathname !== "/") {
      setDataLoading(true);
      try {
        const data = {
          // user_id: "18cbb0c011b/u",
          user_id: user_id,
        };
        const [basicInfo, walletInfo] = await Promise.all([
          await apiRouterCall({
            method: "POST",
            endPoint: "userProfile",
            data: data,
          }),
          await apiRouterCall({
            method: "POST",
            endPoint: "userWallet",
            data: data,
          }),
        ]);

        if (
          basicInfo.data.status === 200 &&
          walletInfo.data.responseCode === 200
        ) {
          setDataLoading(false);
          setUserData({
            profile:
              basicInfo &&
              basicInfo.data &&
              basicInfo.data.params &&
              basicInfo.data.params.profile_info,
            wallet:
              walletInfo &&
              walletInfo.data &&
              walletInfo.data.result &&
              walletInfo.data.result.wallets,
            kycDetails:
              basicInfo &&
              basicInfo.data &&
              basicInfo.data.params &&
              basicInfo.data.params.kycData,
          });
        } else if (basicInfo.data.responseCode === 405) {
          setIsMaintenanceActive(true);
          setDataLoading(false);
        }
        setDataLoading(false);
      } catch (error) {
        // console.log(error);
        setDataLoading(false);
      }
    }
  };

  // funtion to get the about us of tarality
  const aboutData = async () => {
    try {
      const response = await apiRouterCall({
        method: "GET",
        // url: staticContentURL,
      });
      if (response.data.status) {
        setStaticContent(response.data.data);
        // const getData = response?.data?.data?.find((item) => item.page_id === "about_us")
        // setResponseValue(getData);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  //function to get the user KYC status
  const getKYCStatus = async (user_id) => {
    if (window.location.pathname !== "/") {
      try {
        const response = await apiRouterCall({
          method: "GET",
          endPoint: "checkStatus",
          data: {
            user_id: user_id,
          },
        });

        // console.log("kyc--status", response);
        if (response.data.responseCode === 200) {
          setKycStatus(response?.data?.result);
          setIsMaintenanceActive(false);
        } else if (response?.data?.responseCode === 405) {
          setIsMaintenanceActive(true);
        }
      } catch (error) {
        // console.log(error);
        console.log("response--error--", error?.response?.data);
      }
    }
  };

  //function to get the user Bank status
  const getBankStatus = async (user_id) => {
    if (window.location.pathname !== "/") {
      try {
        setViewBank({});
        const response = await apiRouterCall({
          method: "GET",
          endPoint: "bankStatus",
        });
        if (response?.data?.responseCode === 200) {
          setViewBank(response?.data?.result);
        }
      } catch (error) {
        // console.log(error);
      }
    }
  };
  //function to get the user Bank status
  const getNomineeDetails = async (user_id) => {
    try {
      setViewBank({});
      const response = await apiRouterCall({
        method: "GET",
        endPoint: "getNomineeList",
      });
      if (response?.data?.responseCode === 200) {
        setViewNominee(response?.data?.result);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  //context data object that has been passed to the data layer of the application
  let data = {
    userData,
    viewBank,
    viewNominee,
    timeLeft,
    kycStatus,
    staticContent,
    isMaintenanceActive,
    userLoggedIn: isLogin,
    dataLoading,
    bannerList,
    bannerLoading,
    setIsMaintenanceActive: (data) => setIsMaintenanceActive(data),
    getUserProfileHandler: (data) => getUserProfileHandler(data),
    getKYCStatus: (data) => getKYCStatus(data),
    setEndtime: (data) => setEndtime(data),
    getBankStatus: () => getBankStatus(),
    getNomineeDetails: () => getNomineeDetails(),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  useEffect(() => {
    // if (window.location.pathname !== "/") {
    //   aboutData();
    // }

    if (window.sessionStorage.getItem("user_id")) {
      getBankStatus();
      getUserProfileHandler(window.sessionStorage.getItem("user_id"));

      getKYCStatus(window.sessionStorage.getItem("user_id"));

      if (window.location.pathname === "/user-account-settings") {
        getNomineeDetails();
      }
    }
    return;
  }, [window.sessionStorage.getItem("user_id")]); // eslint-disable-line

  useEffect(() => {
    if (endTime) {
      const timer = setTimeout(() => {
        setTimeLeft(calculateTimeLeft(endTime * 1000));
      }, 1000);
      return () => clearTimeout(timer);
    }
  });

  // console.log(bankStatus);
  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}

import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
// import Button from "@mui/material/Button";
// import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import Draggable from "react-draggable";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, Hidden, IconButton, Typography } from "@mui/material";
import { AuthContext } from "../context/auth";
import { useNavigate } from "react-router";
import { colors } from "../assets/colors";
import SupportModal from "./modals/SupportModal";

const FloatingButton = styled("div")({
  position: "fixed",
  bottom: 20,
  right: 20,
  zIndex: 1000, // Ensure it's above other elements
});

export default function SupportButton() {
  const navigate = useNavigate();
  const { userLoggedIn } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  console.log(">>>>>>isDragging", isDragging);
  const dragStartTimeout = useRef(null);
  const handleDragStart = () => {
    setIsDragging(false);
    dragStartTimeout.current = setTimeout(() => {
      setIsDragging(true);
    }, 150); // If the drag starts within 150ms, we consider it a "drag"
  };

  const handleDragStop = () => {
    if (dragStartTimeout.current) {
      clearTimeout(dragStartTimeout.current);
    }
  };

  return (
    <Fragment>
      <Hidden lgDown>
        <Draggable
          onStart={handleDragStart}
          onStop={handleDragStop}
          defaultPosition={{ x: 20, y: 200 }}
          bounds="parent" // This will keep the button within the parent element (the whole viewport in this case)
        >
          <Box
            display="flex"
            alignItems={"center"}
            sx={{
              borderRadius: "20px",
              background: "#432ae1",
              padding: "0px 10px",
              cursor: "pointer",
              maxWidth: "150px",
              position: "relative",
              zIndex: "111",
            }}
            onTouchEnd={() => {
              if (userLoggedIn) {
                navigate("/support/raise-tickets");
              } else {
                setOpen(true);
              }
            }}
            onClick={() => {
              if (userLoggedIn) {
                navigate("/support/raise-tickets");
              } else {
                setOpen(true);
              }
            }}
          >
            <IconButton>
              <SupportAgentIcon sx={{ color: colors.white }} />
            </IconButton>
            <Typography
              variant="body2"
              fontWeight={600}
              sx={{ color: colors.white }}
            >
              Need Help?
            </Typography>
          </Box>
        </Draggable>
      </Hidden>
      <Hidden lgUp>
        <FloatingButton>
          <Box
            display="flex"
            alignItems={"center"}
            sx={{
              borderRadius: "20px",
              background: "#432ae1",
              padding: "0px 10px",
              cursor: "pointer",
              maxWidth: "150px",
            }}
            onClick={() => {
              if (userLoggedIn) {
                navigate("/support/raise-tickets");
              } else {
                setOpen(true);
              }
            }}
          >
            <IconButton>
              <SupportAgentIcon sx={{ color: colors.white }} />
            </IconButton>
            <Typography
              variant="body2"
              fontWeight={600}
              sx={{ color: colors.white }}
            >
              Need Help?
            </Typography>
          </Box>
        </FloatingButton>
      </Hidden>
      {open && <SupportModal open={open} close={() => setOpen(false)} />}
    </Fragment>
  );
}

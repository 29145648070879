import _ from "lodash";
import { typographyBase } from "./typography";
import { createTheme, responsiveFontSizes } from "@mui/material";
import { colors } from "../assets/colors";

const baseOptions = {
  direction: "ltr",
  typography: {
    ...typographyBase,
  },
  overrides: {},
};

const themesOptions = [
  // {
  //   direction: "ltr",
  //   name: "LIGHT",
  //   components: {
  //     MuiStepper: {
  //       styleOverrides: {
  //         // Override the completed icon color
  //         icon: {
  //           color: "green", // Change to your desired color
  //         },
  //       },
  //     },
  //     MuiButton: {
  //       styleOverrides: {
  //         root: {
  //           "&:hover": {
  //             color: colors.gold,
  //           },
  //         },
  //       },
  //     },
  //     MuiIconButton: {
  //       styleOverrides: {
  //         root: {
  //           "&:hover": {
  //             color: colors.gold,
  //           },
  //         },
  //       },
  //     },
  //     MuiInputBase: {
  //       styleOverrides: {
  //         root: {
  //           "&.MuiOutlinedInput-root": {
  //             // background: colors.white,
  //             background: colors.textFieldGrey,
  //             color: colors.darkPrimary,
  //             paddingRight: "0px",
  //           },
  //         },
  //       },
  //     },
  //     MuiPaper: {
  //       styleOverrides: {
  //         root: {
  //           // backgroundColor: colors.white,
  //           borderRadius: "10px",
  //           boxShadow: "none",
  //           "&.MuiCard-root": {
  //             background: "#1d1d1d0a",
  //             color: colors.darkPrimary,
  //           },
  //         },
  //       },
  //     },
  //     MuiTableCell: {
  //       styleOverrides: {
  //         root: {
  //           borderBottom: "none",
  //         },
  //       },
  //     },
  //     MuiStepConnector: {
  //       styleOverrides: {
  //         root: {
  //           top: "19px",
  //           margin: "0px 19px",
  //         },
  //         line: {
  //           borderColor: colors.gold,
  //           // minHeight: "60px",
  //           // width: "2px",
  //         },
  //       },
  //     },
  //     MuiSvgIcon: {
  //       root: {
  //         MuiStepIcon: {
  //           root: {
  //             Mui: {
  //               completed: {
  //                 color: "white !important",
  //               },
  //             },
  //           },
  //         },
  //       },
  //     },
  //     MuiStepIcon: {
  //       styleOverrides: {
  //         // completed: {
  //         //   color: "green !important", // Change to your desired color
  //         // },
  //         "&.Mui-completed": {
  //           color: "green !important",
  //         },
  //       },
  //     },
  //     MuiFormHelperText: {
  //       styleOverrides: {
  //         root: {
  //           marginLeft: "0px",
  //         },
  //       },
  //     },
  //     MuiButtonBase: {
  //       styleOverrides: {
  //         root: {
  //           "&.MuiCheckbox-root.Mui-checked": {
  //             color: colors.gold,
  //           },
  //           "&.MuiRadio-root.Mui-checked": {
  //             color: colors.gold,
  //           },
  //         },
  //       },
  //     },

  //     MuiBadge: {
  //       styleOverrides: {
  //         badge: {
  //           backgroundColor: colors.gold,
  //         },
  //       },
  //     },
  //   },
  //   palette: {
  //     type: "light",
  //     action: {
  //       active: colors.darkPrimary,
  //     },
  //     background: {
  //       default: "rgba(255, 255, 255, 1);",
  //       dark: "#f4f6f8",
  //       paper: "#ECECEC",
  //     },
  //     primary: {
  //       main: colors.cardBackground,
  //       dark: colors.white,
  //     },
  //     secondary: {
  //       main: colors.darkPrimary,
  //     },
  //     text: {
  //       primary: colors.darkPrimary,
  //       secondary: colors.darkSecondary,
  //     },
  //   },
  // },
  {
    direction: "ltr",
    name: "DARK",
    components: {
      MuiStepper: {
        styleOverrides: {
          // Override the completed icon color
          icon: {
            color: "green !important", // Change to your desired color
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            "&:hover": {
              color: colors.gold,
            },
          },
        },
      },
      MuiSwitch: {
        styleOverrides: {
          root: {
            "& .MuiSwitch-thumb": {
              color: colors.cardBackground,
            },
            "& .Mui-checked": {
              color: colors.cardBackgroundLight,
            },
            "&.Mui-checked .MuiSwitch-track": {
              backgroundColor: "#ff5722",
            },
            "& .MuiSwitch-track": {
              backgroundColor: colors.cardBackground,
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            color: colors.btnBlue,
            "&:hover": {
              color: colors.gold,
            },
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            border: "0.5px solid #c4c4c4",
            borderRadius: "6px",
            "&.MuiOutlinedInput-root": {
              border: "0.5px solid #c4c4c4",
              background: colors.textFieldGrey,
              color: colors.white,
              "&:hover": {
                borderColor: colors.lightGray,
              },
            },
          },
          input: {
            "&.MuiOutlinedInput-input": {
              borderColor: colors.lightGray,
              background: colors.textFieldGrey,
              color: colors.black,
              borderRadius: "6px",
            },
            "&.Mui-disabled": {
              color: "#000 !important", // Change the text color for disabled input fields
              "-webkit-text-fill-color": "#000 !important",
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: "0px",
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // backgroundColor: "transparent !important",
            borderRadius: "10px",
            border: "1px solid #c4c4c4",
            color: colors.black,
            "&.MuiCard-root": {
              background: "transparent !important",
              color: colors.black,
              border: "1px solid #c4c4c4",
            },
          },
        },
      },
      // MuiAutocomplete: {
      //   styleOverrides: {
      //     root: {
      //       backgroundColor: "orange",
      //       borderRadius: "8px",
      //       boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      //     },
      //   },
      // },
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: "none",
            color: colors.black,
          },
        },
      },
      MuiStepConnector: {
        styleOverrides: {
          root: {
            top: "19px",
            margin: "0px 19px",
          },
          line: {
            borderColor: colors.gold,
            // minHeight: "60px",
            // width: "2px",
          },
        },
      },
      MuiStepLabel: {
        root: {
          padding: "0px",
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            "&.MuiCheckbox-root.Mui-checked": {
              color: colors.gold,
            },
            "&.MuiRadio-root.Mui-checked": {
              color: colors.gold,
            },
          },
        },
      },

      MuiBadge: {
        styleOverrides: {
          badge: {
            backgroundColor: colors.gold,
          },
        },
      },
    },

    palette: {
      type: "dark",
      action: {
        active: colors.white,
        hover: "rgba(255, 255, 255, 0.04)",
        selected: "rgba(255, 255, 255, 0.08)",
        disabled: "rgba(255, 255, 255, 0.26)",
        disabledBackground: "rgba(255, 255, 255, 0.12)",
        focus: "rgba(255, 255, 255, 0.12)",
      },
      background: {
        default: "#F5F5F5;",
        dark: "#1c2025",
        paper: colors.white,
      },
      primary: {
        main: colors.darkPrimary,
        dark: colors.bgDark,
      },
      secondary: {
        main: colors.white,
      },
      text: {
        primary: colors.white,
        secondary: colors.white,
      },
    },
    // shadows: strongShadows,
  },
];

export const createAppTheme = (config = {}) => {
  let themeOptions = themesOptions.find((theme) => theme.name === config.theme);

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    [themeOptions] = themesOptions;
  }

  let theme = createTheme(
    _.merge({}, baseOptions, themeOptions, { direction: config.direction })
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }
  return theme;
};

import React, { useContext, useEffect } from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
// import TopBar from "./TopBar";
import { styled } from "@mui/material/styles";
import ThemeContext from "../../context/themeContext";
import Footer from "./Footer";
import TopBar from "../DashboardLayout/TopBar";
import { useLocation } from "react-router";
import MaintenanceMode from "../../components/MaintenanceMode";
import SupportButton from "../../components/SupportButton";
import FormCard from "../../components/FormCard";

const LayoutContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,
  height: "100%",
  width: "100%",
  position: "relative",
}));

/*
This component is HOC to unify the header and footer throughout the application for home layout
*/
export default function HomeLayout({ children }) {
  const location = useLocation();
  const themeSettings = useContext(ThemeContext);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [location.pathname]);

  return (
    <LayoutContainer>
      <TopBar themeSettings={themeSettings} isDashboard={false} />
      <FormCard>
        {/* <Box mt={8}></Box> */}
        <SupportButton />
        <Box>{children}</Box>

        <Footer themeSettings={themeSettings} />
      </FormCard>

      <MaintenanceMode />
    </LayoutContainer>
  );
}

HomeLayout.propTypes = {
  children: PropTypes.node,
};

import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Divider,
  Grid,
  Typography,
  List,
  ListSubheader,
  ListItemText,
  IconButton,
  ListItem,
} from "@mui/material";
import Logo from "../../components/Logo";
import { colors } from "../../assets/colors";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import RedditIcon from "@mui/icons-material/Reddit";
import { RxDiscordLogo } from "react-icons/rx";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import FormCard from "../../components/FormCard";

const FooterContainer = styled(Box)(({ theme }) => ({
  // backgroundColor: theme.palette.primary.dark,
  // backgroundColor: colors.primaryWhite,
  color: theme.palette.secondary.main,
  height: "100%",
  width: "100%",
  padding: "50px 30px",
}));

// data list footer right container
const footerOptions = [
  {
    title: "Company",
    isIcons: false,
    options: [
      { name: "About", href: "/about" },
      { name: "Announcements", href: "/resources/all" },
      { name: "Learn", href: "" },
      { name: "Careers", href: "" },
      { name: "Press", href: "" },
      { name: "Newsletter", href: "" },
      { name: "Downloads", href: "" },
      { name: "Business Contact", href: "/business-contact" },
    ],
  },
  {
    title: "Products",
    isIcons: false,
    options: [
      // { name: "Taral Earn", href: "" },
      { name: "Taral Banking", href: "" },
      // { name: "Taral Drop", href: "" },
      { name: "Buy Now Pay Later", href: "" },
    ],
  },
  {
    title: "Services",
    isIcons: false,
    options: [
      { name: "INR Trade", href: "" },
      { name: "Request Token", href: "" },
      { name: "Unique Trade", href: "" },
      { name: "Safe Trade", href: "" },
      { name: "Download App(Coming Soon)", href: "" },
    ],
  },
  {
    title: "Buy Crypto",
    isIcons: false,
    options: [
      { name: "Buy Bitcoin", href: "" },
      { name: "Buy Ethereum", href: "" },
      { name: "Buy Taral", href: "" },
      { name: "Buy INRX", href: "" },
      { name: "Buy Matic", href: "" },
    ],
  },
  {
    title: "Support",
    isIcons: false,
    options: [
      { name: "Raise a Ticket", href: "/support/raise-tickets" },
      { name: "Support Center", href: "/support/raise-tickets" },
      { name: "User Guide", href: "" },
    ],
  },
  {
    title: "Community",
    isIcons: true,
    options: [
      {
        id: "twitter",
        icon: <TwitterIcon />,
        href: "https://twitter.com/TaralityCoin",
      },
      { id: "telegram", icon: <TelegramIcon />, href: "https://t.me/Tarality" },
      {
        id: "linkedIn",
        icon: <LinkedInIcon />,
        href: "https://www.linkedin.com/company/tarality/",
      },
      {
        id: "instagram",
        icon: <InstagramIcon />,
        href: "https://www.instagram.com/taralitycoin/",
      },
      {
        id: "facebook",
        icon: <FacebookIcon />,
        href: "https://www.facebook.com/TaralityCoin",
      },
      {
        id: "youtube",
        icon: <YouTubeIcon />,
        href: "https://www.youtube.com/@taralitycoin",
      },
      {
        id: "reddit",
        icon: <RedditIcon />,
        href: "https://www.reddit.com/user/taralityecosystem",
      },
      {
        id: "discord",
        icon: <RxDiscordLogo />,
        href: "https://discord.gg/8chawtthnj",
      },
    ],
  },
];

//main export const which is rendered in the home layout
export default function Footer({ themeSettings }) {
  return (
    <FooterContainer>
      <Grid container spacing={3}>
        <Grid
          item
          lg={5}
          sm={5}
          md={5}
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
        >
          {/* <Logo
            style={{ width: "60%", minWidth: "100px", maxWidth: "250px" }}
          /> */}
          <Grid
            container
            item
            xs={12}
            justifyContent={"flex-start"}
            alignItems={"center"}
          >
            <Box
              component={"img"}
              className="Logoimg"
              src={
                // themeSettings.settings.theme === "DARK"
                //   ? "/assets/logo/logo_dark.svg"
                //   : "/assets/logo/logo_light.png"

                "/assets/logo/logo_light.png"
              }
              height={"60px"}
              alt="site_logo"
            />
          </Grid>

          <Box sx={{ width: "80%", mt: 4 }}>
            <Typography variant="body1" sx={{ color: "#797979" }}>
              Crypto currency investment is risky and unregulated in India. We
              only provide services, Tarality will not be responsible for any
              loss or profit. E&OE
            </Typography>
          </Box>

          <Divider orientation="vertical" flexItem />
        </Grid>

        <Grid item lg={7} sm={7} md={7} xs={12}>
          <FooterRightContent themeSettings={themeSettings} />
        </Grid>
      </Grid>
      <FooterCopyrightBar />
    </FooterContainer>
  );
}

const FooterRightContent = ({ themeSettings }) => {
  const listHeaderStyles = {
    color: colors.black,
    backgroundColor: "transparent",
    fontSize: "18px",
    paddingLeft: "0px",
  };

  const navigate = useNavigate();

  return (
    <Grid container spacing={2}>
      {footerOptions &&
        footerOptions.map((item, i) => {
          return (
            <Grid item lg={2} xs={6} md={4} sm={6} key={`footerOptions${i}`}>
              <List
                sx={{ width: "100%", maxWidth: 360 }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    sx={listHeaderStyles}
                  >
                    {item.title}
                  </ListSubheader>
                }
              >
                {!item.isIcons ? (
                  item.options.map(({ name, href }) => {
                    return (
                      <ListItem
                        key={name}
                        sx={{
                          padding: "0px 16px 0px 0px",
                          cursor: "pointer",
                          color: "#797979",
                          "&:hover": {
                            color: colors.cardBackground,
                          },
                        }}
                      >
                        <ListItemText
                          primary={name}
                          onClick={() => navigate(href)}
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Box>
                    {item.options.map(({ icon, href, id }) => {
                      return (
                        <IconButton
                          key={id}
                          onClick={() => window.open(href)}
                          sx={{
                            color: colors.cardBackground,
                            ":hover": {
                              color: "#74adff",
                            },
                          }}
                        >
                          {icon}
                        </IconButton>
                      );
                    })}
                  </Box>
                )}
              </List>
            </Grid>
          );
        })}
    </Grid>
  );
};

const FooterCopyrightBar = () => {
  const textClass = {
    textTransform: "none",
    margin: "0px 10px",
    cursor: "pointer",
    "&:hover": {
      color: colors.gold,
    },
  };
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        marginTop: "50px",
      }}
    >
      <Grid container>
        <Grid item xs={12} sm={4} md={6}>
          <Box
            sx={{
              display: { xs: "flex" },
              alignItems: { xs: "center", sm: "center" },
              justifyContent: { xs: "center", sm: "end" },
            }}
          >
            <Typography variant="body1">
              {`© ${moment().format("YYYY")} Tarality.`}
            </Typography>

            <Typography
              variant="body1"
              sx={textClass}
              onClick={() => navigate("/privacy-policy")}
            >
              Privacy Policy
            </Typography>

            <Typography
              variant="body1"
              sx={textClass}
              onClick={() => navigate("/terms-conditions")}
            >
              Terms & Conditions
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={1}>
          <Box
            sx={{
              display: { xs: "flex" },
              alignItems: { xs: "center", sm: "center" },
              justifyContent: { xs: "center", sm: "start" },
              marginTop: { xs: "5px", sm: "0px" },
            }}
          >
            {/* <Typography
              variant="body1"
              sx={textClass}
              onClick={() => navigate("")}
            >
              Cookie Policy
            </Typography>

            <Typography
              variant="body1"
              sx={textClass}
              onClick={() => navigate("")}
            >
              Trade Policy
            </Typography> */}

            <Typography
              variant="body1"
              sx={textClass}
              onClick={() => navigate("/kyc-policy")}
            >
              KYC & AML Policy
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Box
            sx={{
              display: { xs: "flex" },
              alignItems: { xs: "center", sm: "center" },
              justifyContent: { xs: "center", sm: "start" },
              marginTop: { xs: "5px", sm: "0px" },
            }}
          >
            <Typography
              variant="body1"
              sx={textClass}
              onClick={() => navigate("/borrow-policy")}
            >
              Borrow Policy
            </Typography>
            <Typography
              variant="body1"
              sx={textClass}
              onClick={() => navigate("/bnpl-policy")}
            >
              BNPL Policy
            </Typography>
            <Typography
              variant="body1"
              sx={textClass}
              onClick={() => navigate("/account-deletion-policy")}
            >
              Account Deletion Policy
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

import React from "react";
import { useContext } from "react";
import ThemeContext from "../context/themeContext";

export default function Logo(props) {
  const themeSettings = useContext(ThemeContext);
  return (
    <img
      className="Logoimg"
      src={
        // themeSettings.settings.theme === "DARK"
        //   ? "/assets/logo/logo_dark.svg"
        //   : "/assets/logo/logo_light.png"

        "/assets/logo/logo_dark.svg"
      }
      {...props}
      alt="site_logo"
    />
  );
}

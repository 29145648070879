import {
  Box,
  Divider,
  IconButton,
  Tooltip,
  Badge,
  Toolbar,
  Grid,
} from "@mui/material";
import { React, useContext, useState, useMemo, useEffect } from "react";
import { useNavigate } from "react-router";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";

import NotificationModal from "../../../components/modals/NotificationModal";
import { AuthContext } from "../../../context/auth";
import { FlexLayerContext } from "../../../context/flexLayer";
import LogoutIcon from "@mui/icons-material/Logout";
import toast from "react-hot-toast";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import { colors } from "../../../assets/colors";
import FlexButton from "../../../components/FlexButton";

import SavingsIcon from "@mui/icons-material/Savings";
import io from "socket.io-client";

import { notification_socket_url } from "../../../api";
export default function RightOptions({ themeSettings, sx }) {
  const { userLoggedIn } = useContext(AuthContext);
  const { notificationData } = useContext(FlexLayerContext);

  const [isActive, setIsActive] = useState(false);
  const navigate = useNavigate();
  const [openLogout, setOpenLogout] = useState(false);
  const auth = useContext(AuthContext);

  const notificationModal = useMemo(() => {
    return (
      <NotificationModal
        open={isActive}
        close={() => setIsActive(false)}
        data={notificationData?.data}
      />
    );
  }, [isActive]);
  const logoutHandler = () => {
    auth.userLogIn(false, null);
    toast.success("You have successfully logged out!");
    navigate("/login");
  };
  return (
    <Box sx={sx}>
      {!userLoggedIn && (
        <Grid container item xs={12} flexDirection={"row"} minWidth={"180px"}>
          <Grid item xs={6}>
            <FlexButton
              title="Login"
              color="secondary"
              disabled={false}
              fullWidth={false}
              sx={{
                marginRight: "10px",
              }}
              onClick={() => navigate("/login")}
            />
          </Grid>
          <Grid item xs={6}>
            <FlexButton
              title="Sign Up"
              color="primary"
              disabled={false}
              fullWidth={false}
              onClick={() => navigate("/signup")}
            />
          </Grid>
        </Grid>
      )}
      {userLoggedIn && (
        <>
          <Tooltip title="Profile">
            <IconButton
              onClick={() => navigate("/user-profile")}
              sx={{
                color: colors.primaryWhite,
                transition: "all 200ms ease-in-out 50ms",
                ":hover": {
                  color: colors.cardBackground,
                },
              }}
            >
              <AccountCircleIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Saving Account">
            <IconButton
              onClick={() => navigate("/wallet")}
              sx={{
                color: colors.primaryWhite,
                transition: "all 200ms ease-in-out 50ms",
                ":hover": {
                  color: colors.cardBackground,
                },
              }}
            >
              <SavingsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications">
            <IconButton
              style={{ marginRight: "10px" }}
              onClick={() => setIsActive(true)}
              sx={{
                color: colors.primaryWhite,
                transition: "all 200ms ease-in-out 50ms",
                ":hover": {
                  color: colors.cardBackground,
                },
              }}
            >
              <Badge
                badgeContent={notificationData && notificationData?.unReadCount}
                color="secondary"
              >
                <NotificationsIcon color="secondary" />
              </Badge>
            </IconButton>
          </Tooltip>
        </>
      )}
      {userLoggedIn && (
        <>
          <Divider
            orientation="vertical"
            flexItem
            sx={{
              backgroundColor:
                themeSettings.settings.theme === "DARK" ? "#b9b3b3" : "#fff",
              margin: "0px 4px",
            }}
          />

          <Tooltip title="Logout">
            <IconButton
              onClick={() => {
                setOpenLogout(true);
              }}
              sx={{
                color: colors.primaryWhite,
                transition: "all 200ms ease-in-out 50ms",
                ":hover": {
                  color: colors.red,
                },
              }}
            >
              <LogoutIcon />
            </IconButton>
          </Tooltip>
        </>
      )}

      {isActive && notificationModal}
      {openLogout && (
        <ConfirmationModal
          open={openLogout}
          title="Logout!"
          description="Are you sure want to logout?"
          onClick={logoutHandler}
          close={() => setOpenLogout(false)}
        />
      )}
    </Box>
  );
}

import { colors } from "../assets/colors";

export const typographyBase = {
  h1: {
    fontSize: "3rem",
    fontFamily: "Roboto, sans-serif",
    fontWeight: 900,
    "@media (max-width:1200px)": {
      fontSize: "2.0rem",
    },
    "@media (max-width:767px)": {
      // fontSize: "2.4rem",
      fontSize: "1.7rem",
    },
    color: colors.black,
  },
  h2: {
    fontWeight: 500,
    fontSize: "2.5rem",
    fontFamily: "Roboto, sans-serif",
    "@media (max-width:767px)": {
      fontSize: "1.8rem",
    },
    color: colors.black,
  },
  h3: {
    fontWeight: 600,
    fontSize: "1.8rem",
    fontFamily: "Roboto, sans-serif",
    "@media (max-width:767px)": {
      fontSize: "1.5rem",
    },
    color: colors.black,
  },
  h4: {
    fontWeight: 400,
    fontSize: "1.563rem",
    fontFamily: "Roboto, sans-serif",
    "@media (max-width:767px)": {
      fontSize: "1rem",
    },
    color: colors.black,
  },
  h5: {
    fontWeight: 400,
    fontSize: "1.25rem",
    fontFamily: "Roboto, sans-serif",
    "@media (max-width:767px)": {
      fontSize: "0.875rem",
    },
    color: colors.black,
  },
  h6: {
    fontWeight: 400,
    fontSize: "1.2rem",
    fontFamily: "Roboto, sans-serif",
    "@media (max-width:767px)": {
      fontSize: "1rem",
    },
    color: colors.black,
  },
  body2: {
    fontWeight: 300,
    fontSize: "0.875rem",
    fontFamily: "Roboto, sans-serif",
    // color: "rgba(0, 0, 0, 0.6)",
    "@media (max-width:767px)": {
      fontSize: "0.75rem",
    },
    color: colors.black,
  },
  body1: {
    fontWeight: 400,
    fontSize: "0.75rem",
    fontFamily: "Roboto, sans-serif",
    "@media (max-width:767px)": {
      fontSize: "0.625rem",
    },
    color: colors.black,
  },
  overline: {
    fontWeight: 500,
    color: colors.black,
  },
  button: {
    // textTransform: "capitalize",
    borderRadius: "27px",
    fontFamily: "Roboto, sans-serif",
    color: colors.black,
  },
  subtitle2: {
    fontWeight: 400,
    fontSize: "0.75rem",
    fontFamily: "Roboto, sans-serif",
    color: colors.black,
  },
  subtitle3: {
    fontWeight: 500,
    fontSize: "0.6875rem",
    fontFamily: "Roboto, sans-serif",
    color: colors.black,
  },
};

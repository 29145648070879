import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, Drawer, Hidden, List, ListSubheader } from "@mui/material";
import NavItem from "./NavItem";
import { styled } from "@mui/system";
import ThemeContext from "../../../context/themeContext";
import { colors } from "../../../assets/colors";
import { matchPath, useLocation } from "react-router";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
const MobileDrawer = styled(Drawer)(({ theme }) => ({
  width: 272,
  "& .MuiPaper-root": {
    borderRadius: "5px",
  },
}));

const DesktopDrawer = styled(Drawer)(({ theme }) => ({
  top: "95px",
  width: "200px",
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.secondary.main,

  "& .MuiPaper-root": {
    borderRadius: "0px",
    top: 72,
  },
}));
const LogoutButton = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: "100px",
  width: "100%",
}));

const SideMenuBox = styled(Box)(({ theme }) => ({
  width: "200px",
  // backgroundColor: theme.palette.primary.main,
  // color: theme.palette.secondary.main,
  "& .MuiCollapse-wrapperInner": {
    marginLeft: "45px",
  },
}));

function renderNavItems({ items, pathname, depth = 0, onMobileClose }) {
  return (
    <List disablePadding onClick={onMobileClose}>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({ acc, item, pathname, depth, onMobileClose }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth, onMobileClose }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false,
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        onMobileClose={onMobileClose}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        onMobileClose={onMobileClose}
      />
    );
  }

  return acc;
}

const sections = [
  {
    items: [
      {
        title: "Account",
        // icon: AiFillHome,
        href: "/user-profile",
      },
      // {
      //   title: "KYC Status",
      //   // icon: AiFillDashboard,
      //   href: "/user-kyc-info",
      // },

      {
        title: "Settings",
        // icon: AiFillDashboard,
        href: "/user-account-settings",
      },

      // {
      //   title: "Referral",
      //   // icon: AiFillDashboard,
      //   href: "/referrals",
      // },
    ],
  },
];

const NavBar = ({ onMobileClose, openMobile }) => {
  const route = useLocation();
  const themeSettings = useContext(ThemeContext);
  const themeColors = {
    color: colors.primaryWhite,
    boxShadow: "none",
    background:
      "linear-gradient(101deg, #a7aaf1 -2.08%, #3147c5fc 52.7%, #04042d 104.17%) !important",
  };
  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      // justifyContent="center"
      sx={themeColors}
      position="relative"
    >
      <Box pt={2} pb={2}>
        <SideMenuBox>
          {sections?.map((section, i) => (
            <List
              key={`menu${i}`}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                img: section.img,
                items: section.items,
                pathname: route.pathname,
                state: section.tabview,
                onMobileClose: onMobileClose,
              })}
            </List>
          ))}
        </SideMenuBox>
      </Box>

      {/* Rest of your code */}
    </Box>
  );

  const logoutButton = (
    <List sx={{ width: "100%" }}>
      <NavItem
        depth={0}
        href={"/logout"}
        icon={<ExitToAppIcon color="secondary" />}
        info={""}
        key={"logout"}
        title={"Logout"}
      />
    </List>
  );

  return (
    <>
      <Hidden lgUp>
        {openMobile && (
          <MobileDrawer
            anchor="left"
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            <Box
              p={2}
              height="100%"
              className="displaySpacebetween"
              flexDirection="column"
              sx={{
                background:
                  "linear-gradient(101deg, #a7aaf1 -2.08%, #3147c5fc 52.7%, #04042d 104.17%) !important",
              }}
            >
              {content}
              {/* {logoutButton} */}
            </Box>
          </MobileDrawer>
        )}
      </Hidden>
      <Hidden mdDown>
        <DesktopDrawer anchor="left" open variant="persistent">
          {content}
          {/* <LogoutButton>{logoutButton}</LogoutButton> */}
        </DesktopDrawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

export default NavBar;

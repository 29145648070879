// export const colors = {
//   darkPrimary: "#1D1D1D",
//   darkSecondary: "#2C2C2C",
//   bgDark: "#272727",
//   white: "#fff",
//   black: "#000",
//   darkGrey: "#404040",
//   gold: "#ffc107",
//   btnGold: "#FFCA65",
//   red: "#FB3E3E",
//   green: "#01bc8d",
// };

// export const colors = {
//   darkPrimary: "#ffffff",
//   darkSecondary: "#408effba",
//   bgDark: "#272727",
//   white: "#ffffff",
//   black: "#000",
//   darkGrey: "#404040",
//   // gold: "#ffc107",
//   gold: "#408effba",
//   btnGold: "#E8751A",
//   red: "#FB3E3E",
//   green: "#01bc8d",
//   cardBackground: "#408effba",
//   cardBackgroundLight: "#4090ff74",
//   primaryWhite: "#ffffff",
//   buttonBg: "#d6d2fb",
//   blueText: "#2e368f",
//   lightGray: "#c4c4c4",
//   btnBlue: "#432ae1",
// };

export const colors = {
  darkPrimary: "#ffffff",
  darkSecondary: "#408effba",
  bgDark: "#272727",
  white: "#ffffff",
  black: "#000",
  darkGrey: "#404040",
  // gold: "#ffc107",
  gold: "#408effba",
  btnGold: "#E8751A",
  red: "#FB3E3E",

  cardBackground: "#0568f7ba",
  cardBackgroundLight: "#4090ff74",
  primaryWhite: "#ffffff",
  buttonBg: "#d6d2fb",
  blueText: "#2e368f",
  lightGray: "#c4c4c4",
  btnBlue: "#432ae1",
  green: "#43c530",
  textFieldGrey: "transparent !important",
};

// backgroundColor: "#fffcf9";

export const baseURL = "https://stgapi-bnpl.tarality.io";
// export const socket_url = "https://prodsocket.tarality.com";
export const socket_url = "https://socket.tarality.io";
export const notification_socket_url = "https://stgapi-bnpl.tarality.io";
export const verifyaadhar = "https://stgapi-bnpl.tarality.io";

// production server
// export const baseURL = "https://api.tarality.com";
// export const socket_url = "https://prodsocket.tarality.io";
// export const notification_socket_url = "https://api.tarality.com";
// export const verifyaadhar = "https://apiv2.tarality.io";

let version = "api"; //v0
let version_v1 = "api/v1"; //v0
let version_v2 = "api/v2"; //v0
let kyc = `kyc`;
let user = `${version_v1}/user`;
let plan = `${version_v1}/user/plan`;
let cbip = `${version_v1}/cbip`;
let graph = `${version_v1}/graph`;
let bnpl = `${version_v1}/bnpl`;
let bank = `${version_v1}/bank`;
let lockfund = `${version_v1}/lockfund`;
let reward = `${version_v1}/reward`;
let help = `${version_v1}/help`;
let notification = `${version_v1}/notification`;

const apiConfigs = {
  socialLogin: `${baseURL}/${version}/login-social`,
  userProfile: `${baseURL}/${version}/get-profile-info`,
  userWallet: `${baseURL}/${version_v1}/user/getWalletBalances`,
  // userWallet: `${baseURL}/${version}/get-wallets-new`,
  signUp: `${baseURL}/${version}/register-user`,
  userLogin: `${baseURL}/${version}/login-new`,
  verifyOTP: `${baseURL}/${version}/verifyOTP`,
  getGoogleAuth: `${baseURL}/${version}/get-auth-google`,
  resetPasswordProfile: `${baseURL}/${version}/reset-password`,
  freezeAccount: `${baseURL}/${version}/freeze-acount`,
  // forgotPassword: `${baseURL}/${version}/forget-password`,
  verifyOTPForgot: `${baseURL}/${version}/varifie/forget-password`,
  changePassword: `${baseURL}/${version}/set-password`,
  gerReferralCode: `${baseURL}/${version}/user/get-referals`,
  updateWallet: `${baseURL}/${version}/update-wallet`,
  loginOtp: `${baseURL}/${version_v2}/user/loginOtp`,
  register: `${baseURL}/${version_v2}/user/register`,
  verifyLoginOtp: `${baseURL}/${version_v2}/user/verifyLoginOtp `,
  verifyOtp: `${baseURL}/${version_v2}/user/verifyOtp`,
  forgotPassword: `${baseURL}/${version_v2}/user/forgotPassword`,
  resetPassword: `${baseURL}/${version_v2}/user/resetPassword`,
  resendOtp: `${baseURL}/${version_v2}/user/resendOtp`,
  mobileOtp: `${baseURL}/${version_v2}/user/mobile_otp`,
  verifyMobileOtp: `${baseURL}/${version_v2}/user/verify_mobile_otp`,
  enableAuthentication: `${baseURL}/${version_v2}/user/enableAuthentication`,

  //----2FFA----//
  get2FaQR: `${baseURL}/${version}/set-auth-google`,
  enable2FA: `${baseURL}/${version}/set-auth-google-otp`,
  sendMobileOTP: `${baseURL}/${version}/send-mobile-varification-otp`,
  verifyMobileOTP: `${baseURL}/${version}/varifie/mobile`,

  //----kyc----//
  updateKycProfile: `${baseURL}/${kyc}/update-profile`,
  checkStatus: `${baseURL}/${version_v1}/${kyc}/check-status`,
  uploadKYC: `${baseURL}/${version_v1}/${kyc}/upload-kyc`,
  verifyPan: `${verifyaadhar}/${version_v1}/${kyc}/verify-pan`,
  validateAadhar: `${verifyaadhar}/${version_v1}/${kyc}/validate-aadhar`,
  verifyAadhar: `${verifyaadhar}/${version_v1}/${kyc}/verify-adhar`,
  submitKYC: `${baseURL}/${version_v1}/${kyc}/submit-kyc`,
  submitKycInternationalUser: `${baseURL}/${version_v1}/${kyc}/submitKycInternationalUser`,
  uploadSelfie: `${verifyaadhar}/${version_v1}/${kyc}/upload-selfie`,

  //----crypto deposit/withdraw----//
  coinList: `${baseURL}/${version}/getallsymbol`,
  getQrCode: `${baseURL}/${version}/get-blockchain-data`,
  cryptoWithdrawHistory: `${baseURL}/${version}/withdraw_history`,
  cryptoDepositHistory: `${baseURL}/${version}/deposit_history`,
  generateWithdrawOTP: `${baseURL}/${version}/get-withdraw`,
  verifyMobile: `${baseURL}/${version}/varifie/mobile-Withdraw`,
  verifyEmail: `${baseURL}/${version}/varifie/email-Withdraw`,
  balanceDetails: `${baseURL}/${user}/balanceDetails`,

  //----crypto banking----//
  activeAssetsList: `${baseURL}/${cbip}/activeAssetsList`,
  investBalance: `${baseURL}/${cbip}/investBalance`,
  investHistory: `${baseURL}/${cbip}/investHistory`,
  getTaralQuantity: `${baseURL}/${cbip}/getTaralQuantity`,
  listCBIList: `${baseURL}/${cbip}/listCBIList`,
  getTotalInvestedFund: `${baseURL}/${cbip}/getTotalInvestedFund`,
  withdrawCryptoBankingFund: `${baseURL}/${cbip}/withdrawCryptoBankingFund`,
  checkLoanEligibility: `${baseURL}/${cbip}/checkLoanEligibility`,
  applyLoan: `${baseURL}/${cbip}/applyLoan`,
  loanHistory: `${baseURL}/${cbip}/loanHistory`,
  payLoan: `${baseURL}/${cbip}/payLoan`,
  paymentHistory: `${baseURL}/${cbip}/paymentHistory`,

  //----graph----//
  graphData: `${baseURL}/${graph}/cmc_graph_data`,

  //----order-book----//
  getpairedCurrency: `${baseURL}/${version}/getpairedCurrency`,

  //--subscriber--//

  subscribe: `${baseURL}/${version_v1}/subscriber/createSubscriber`,

  // --- anouncement---//
  announcmentList: `${baseURL}/${version_v1}/announcement/getAnnouncementList`,

  // --- Notifications---//
  notification: `${baseURL}/${version_v1}/notification/getNotification`,
  del: `${baseURL}/${version_v1}/notification/deleteNotification`,

  // ---Add Bank ----//
  bankStatus: `${baseURL}/${bank}/viewBank`,
  addBank: `${baseURL}/${bank}/addBank`,
  bankList: `${baseURL}/${version}/banking/get-bank`,
  editBank: `${baseURL}/${bank}/editBank`,
  deleteBank: `${baseURL}/${bank}/deleteBank`,

  //----bnpl----//
  listPlan: `${baseURL}/${bnpl}/listPlan`,
  subscribePlan: `${baseURL}/${bnpl}/subscribePlan`,
  subscriptionPlanList: `${baseURL}/${bnpl}/subscriptionPlanList`,
  viewSubscribePlan: `${baseURL}/${bnpl}/viewSubscribePlan`,
  terminatePlan: `${baseURL}/${bnpl}/terminatePlan`,
  paySubscription: `${baseURL}/${bnpl}/paySubscription`,
  listHistoryBnpl: `${baseURL}/${bnpl}/listHistory`,
  checkPaymentAmount: `${baseURL}/${bnpl}/checkPaymentAmount`,
  bnplHistory: `${baseURL}/${bnpl}/bnplHistory`,

  //----inr-deposit-withdraw----//
  getAdminBank: `${baseURL}/${bank}/getAdminBank`,
  uploadImageFile: `${baseURL}/${user}/uploadImageFile`,
  depositRequest: `${baseURL}/${bank}/depositRequest`,
  withdrawRequest: `${baseURL}/${bank}/withdrawRequest`,

  //----locked balances----//
  getLockFundList: `${baseURL}/${lockfund}/getLockFundList`,
  viewLockFund: `${baseURL}/${lockfund}/viewLockFund`,
  getLockWalletList: `${baseURL}/${lockfund}/getLockWalletList`,

  //----user referral----//
  getReferralRewards: `${baseURL}/${user}/getReferralRewards`,
  deleteAccount: `${baseURL}/${user}/deleteAccount`,

  //----reward----//
  getRewardScratch: `${baseURL}/${reward}/getRewardScratch`,
  scratchCard: `${baseURL}/${reward}/scratchCard`,
  getRewardHistory: `${baseURL}/${reward}/getRewardHistory`,

  //----help and support----//
  categoryList: `${baseURL}/${help}/categoryList`,
  raiseTicket: `${baseURL}/${help}/raiseTicket`,
  queryTicketList: `${baseURL}/${help}/queryTicketList`,
  ticketDetails: `${baseURL}/${help}/ticketDetails`,
  reply: `${baseURL}/${help}/reply`,
  queryRaise: `${baseURL}/${help}/queryRaise`,
  submitFeedback: `${baseURL}/${help}/submitFeedback`,

  //----notifiaction----//
  readNotification: `${baseURL}/${notification}/readNotification`,
  notification: `${baseURL}/${notification}/notification`,

  //----payment options----//
  moduleList: `${baseURL}/${user}/moduleList`,
  paymentCoinList: `${baseURL}/${user}/paymentCoinList`,
  //----trade----//
  fastTradeOrder: `${baseURL}/${version}/fast-trade-order`,
  fastTradeHistory: `${baseURL}/${version}/fast-trade-history`,

  // -----crypto loan ------//
  cryptoListPlan: `${baseURL}/${version_v1}/cryptoLoan/cryptoListPlan`,
  cryptoSubscribePlan: `${baseURL}/${version_v1}/cryptoLoan/cryptoSubscribePlan`,
  cryptoSubscriptionPlanList: `${baseURL}/${version_v1}/cryptoLoan/cryptoSubscriptionPlanList`,
  viewCryptoSubscribePlan: `${baseURL}/${version_v1}/cryptoLoan/viewCryptoSubscribePlan`,
  getLoanAmount: `${baseURL}/${version_v1}/cryptoLoan/getLoanAmount`,
  paySubscriptionPlan: `${baseURL}/${version_v1}/cryptoLoan/paySubscriptionPlan`,
  cryptoLoanHistory: `${baseURL}/${version_v1}/cryptoLoan/loanHistory`,
  cryptoPaymentHistory: `${baseURL}/${version_v1}/cryptoLoan/cryptoLoanHistory`,

  // -----bnpl version 2-----//
  listBNPLPlan: `${baseURL}/${version_v2}/bnpl/listBNPLPlan`,
  checkPaymentAmountNew: `${baseURL}/${version_v2}/bnpl/checkPaymentAmount`,
  paySubscriptionNew: `${baseURL}/${version_v2}/bnpl/paySubscription`,
  listHistory: `${baseURL}/${version_v2}/bnpl/listHistory`,
  bnplHistoryNew: `${baseURL}/${version_v2}/bnpl/bnplHistory`,
  viewHistory: `${baseURL}/${version_v2}/bnpl/viewHistory`,
  subscribeBNPLPlan: `${baseURL}/${version_v2}/bnpl/subscribeBNPLPlan`,
  viewBNPLSubscribePlan: `${baseURL}/${version_v2}/bnpl/viewBNPLSubscribePlan`,
  bnplSubscriptionPlanList: `${baseURL}/${version_v2}/bnpl/bnplSubscriptionPlanList`,
  terminateNow: `${baseURL}/${version_v2}/bnpl/terminateNow`,

  //
  newNominee: `${baseURL}/${version_v1}/nominee/newNominee`,
  viewNominee: `${baseURL}/${version_v1}/nominee/viewNominee`,
  getNomineeList: `${baseURL}/${version_v1}/nominee/getNomineeList`,

  //----------Fixed Deposit---------------//
  cryptoFDListPlan: `${baseURL}/${version_v1}/fixedDeposit/cryptoFDListPlan`,
  cryptoFDSubscribePlan: `${baseURL}/${version_v1}/fixedDeposit/cryptoFDSubscribePlan`,
  cryptoFDSubscribeLockFund: `${baseURL}/${version_v1}/fixedDeposit/cryptoFDSubscribeLockFund`,
  fdSubscriptionList: `${baseURL}/${version_v1}/fixedDeposit/fdSubscriptionList`,
  viewFDSubscribePlan: `${baseURL}/${version_v1}/fixedDeposit/viewFDSubscribePlan`,
  withdrawFDPlan: `${baseURL}/${version_v1}/fixedDeposit/withdrawFDPlan`,
  listFDHistory: `${baseURL}/${version_v1}/fixedDeposit/listFDHistory`,
  terminatedFDPlan: `${baseURL}/${version_v1}/fixedDeposit/terminatedFDPlan`,
  listFDTransactionHistory: `${baseURL}/${version_v1}/fixedDeposit/listFDTransactionHistory`,
  fdHistory: `${baseURL}/${version_v1}/fixedDeposit/fdHistory`,
  cryptoLockfundFDListPlan: `${baseURL}/${version_v1}/fixedDeposit/cryptoLockfundFDListPlan`,
  convertLockFundFd: `${baseURL}/${version_v1}/fixedDeposit/convertLockFundFd`,

  // ----Taral Earn/ Drop----//
  getPlanList: `${baseURL}/${plan}/getPlanList`,
  participate: `${baseURL}/${plan}/participate`,
  detailsParticipant: `${baseURL}/${plan}/detailsParticipant`,
  topParticipants: `${baseURL}/${plan}/topParticipants`,
  userPlanDetails: `${baseURL}/${plan}/userPlanDetails`,
  listHistoryPlan: `${baseURL}/${plan}/listHistory`,
  planInvestmentHistory: `${baseURL}/${plan}/planInvestmentHistory`,
  earnDropHistory: `${baseURL}/${plan}/earnDropHistory`,
  staticContentList: `${baseURL}/${version_v1}/static/staticContentList`,
  bannerList: `${baseURL}/${version_v2}/admin/bannerList`,
};

export default apiConfigs;
